import { IAccServices } from "../../models/AccordionServices";
import dallmeier from '../../assets/images/services-dallmeier.webp'

export const accordionData: IAccServices[] = [
    {
        title: 'СКУД',
        content:
            <ul>
                <li>Системы контроля и управления доступом</li>
                <li>Системы домофонии</li>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования </li>
            </ul>
        
    },
    {
        title: 'Видеонаблюдение',
        content:
            <ul>
                <li>Системы аналогового и IP видеонаблюдения</li>
                <li>Системы видеоаналитики </li>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Системы охранной сигнализации',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Пожарная безопасность',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Системы контроля содержания в воздухе вредных веществ',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Звуковые системы',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Система отображения информации</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Ситуационные центры и интегрированные платформы',
        content:
            <ul>
                <li>Система аудиоконференции</li>
                <li>Система видеоконференцсвязи</li>
                <li>Система отображения информации</li>
                <li>Система управления ситуационным центром</li>
                <li>Система коммутации</li>
                <li>Система электронного протоколирования</li>
                <li>Система комплексной защиты информации</li>
                <li>Защищенная видеосвязь</li>
                <li>Конфиденциальная видеосвязь</li>
                <li>Открытая видеосвязь с органами местного самоуправления</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Системы СКС',
        content:
            <div>СКС (структурированная кабельная система) представляет собой наиболее современное многофункциональное сетевое решение, которое включается в класс капитальных сооружений.<br/>При помощи ЛВС обеспечивается универсальная телекоммуникационная инфраструктура здания, а также передача всевозможных сигналов, включая информационные, видео или же аудио.<br/>Наша компания занимается проектированием, монтажом (прокладкой) и обслуживанием СКС. Имеем большой опыт в проектировании и прокладке:<br/>
            <ul>
                <li>компьютерных сетей</li>
                <li>телефонных сетей</li>
                <li>оптоволоконных сетей (есть опыт построения линий связи ВОЛС)</li>
                <li>сварка ВОЛС (а также проектирование и прокладка, в том числе и в канализации)</li>
            </ul>Помимо этого занимаемся проектированием и установкой Wi-Fi сетей, в том числе внедрение бесшовного Wi-Fi.<br/>Также можем взять на обслуживание вашу сеть ЛВС, даже если вы проектировали и прокладывали ее без нас.
            </div>
    },
    {
        title: 'Электропитание',
        content:
            <ul>
                <li>Защита от импульсных и коммутационных перенапряжений</li>
                <li>Двойная изоляция, изолирование рабочего места</li>
                <li>Уравнивание потенциалов</li>
                <li>Автоматическое отключение питания</li>
                <li>Защитное заземление</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Системы триангуляции положения объектов, транспорта, персонала',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Система отображения информации</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Мобильные и переносные комплексы',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Система отображения информации</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Системы передачи данных',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решений</li>
                <li>Система отображения информации</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Wi-Fi точки доступа',
        content:
            <ul>
                <li>Консалтинг</li>
                <li>Проектирование и дизайн решеий</li>
                <li>Система отображения информации</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'IP-АТС, IP-телевидение',
        content:
            <ul>
                <li>Профессиональный консалтинг</li>
                <li>Проектирование и дизайн решеий</li>
                <li>Система отображения информации</li>
                <li>Монтажные и пусконаладочные работы</li>
                <li>Сервисное обслуживание</li>
                <li>Подбор оборудования</li>
            </ul>
    },
    {
        title: 'Panomera Dallmeier - ремонт и обслуживание',
        content:
            <>
                <img src={dallmeier} alt="camera Dallmeier"/>
                <div>Компания <strong>ООО "РС"</strong> осуществляет ремонт и техническое обслуживание систем видеонаблюдения Dallmeier.<br/><br/><strong>Перечень оказываемых услуг:</strong><br/><br/><ul><li>проведение предупредительного и планового ремонта;</li><li>демонтажные, монтажные и пусконаладочные работы;</li><li>установка и настройка программного обеспечения Dallmeier;</li><li>комплексная настройка систем на базе Dallmeier;</li><li>техническое обслуживание и сервисное сопровождение.</li></ul>Наши специалисты обладают высоким уровнем квалификации, имеют все необходимые сертификаты и допуски для качественного проведения работ на объектах любой сложности<br/>Оказываем услуги в Российской Федерации и странах СНГ.<br/>На все выполняемые работы предоставляется гарантия.</div>
            </>

    },
]