import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./pages/Services/Services";
import Products from "./pages/Products/Products";
import Maintenance from "./pages/Maintenance/Maintenance";
import Partners from "./pages/Partners/Partners";
import StartPage from "./pages/Start/Start";
import Footer from "./pages/components/footer/footer";
import Policy from "./pages/Policy/Policy";
import Header from "./pages/components/header/Header";
import Solutions from "./pages/Solutions/Solutions";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import News from "./pages/News/NewsPage";
import TestDrivePage from "./pages/TestDrive/TestDrive";

const App: React.FC = () => {

  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/services" element={<Services/>} />
        <Route path="/solutions" element={<Solutions/>} />
        {/* <Route path="/projects" element={<ProjectsPage/>} /> */}
        <Route path="/products" element={<Products/>} />
        <Route path="/partners" element={<Partners/>} />
        <Route path="/policy" element={<Policy/>} />
        {/* <Route path="/news" element={<News/>} /> */}
        <Route path="/test-drive" element={<TestDrivePage/>} />
        <Route path="/" element={<StartPage/>} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
};

export default App;