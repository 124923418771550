import mark from '../../../assets/images/mark.webp';
import './reasons.scss'

const Reasons: React.FC = () => {   
    return (
        <section className="reasons">
            <div className="container">
                <div className="reasons__text">
                    <h2 className="reasons__text-header">Почему выгодно работать с системным интегратором</h2>
                    <p className="reasons__text-p">Интегратор не только занимается установкой отдельных инженерных комплексов. Он создает целую сеть компонентных подсистем и обеспечивает их совместную работу на объекте. Именно такой компанией является ООО "РС".</p>
                    <p className="reasons__text-p">Формула хорошего интегратора – грамотно выполненный монтаж и стабильно работающее оборудование. Своим клиентам мы предлагаем безупречные системы: современные, эффективные, гибкие.</p>
                </div>
                <img src={mark} alt="mark" className="reasons__img" />
            </div>            
        </section>
    )
}

export default Reasons;