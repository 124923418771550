import imgCompany from "../../assets/images/about-company.webp";
import imgCompetence from "../../assets/images/about-competence.webp";
import imgSolutions from "../../assets/images/about-solutions.webp";
import './about.scss';

const About: React.FC = () => {
    return (
        <section className="about" id="about">
            <div className="arg arg__company">
                <div className="arg__text ">
                    <h2 className="arg__header">Компания</h2>
                    <p className="arg__p">ООО "РС" – компания-интегратор слаботочных и IT систем любой сложности. Решаем задачи разного уровня под ключ. Наша миссия – сделать город безопасным. Компания имеет накопленный опыт с 2003 года, оснащая объекты надежными и технологичными системами.</p>
                </div>
                <img src={imgCompany} alt="chip" className="arg__img" />
            </div>
            <div className="arg arg__competence">
                <div className="arg__text ">
                    <h2 className="arg__header">Компетенция</h2>
                    <p className="arg__p">Строим концепции в сфере систем безопасности и реализуем их на объектах. Выполняем проектирование, инсталляцию, пусконаладку, шеф-монтаж, техническое обслуживание, авторский надзор, сопровождение в эксплуатации комплексов cистем безопасности.</p>
                </div>
                <img src={imgCompetence} alt="camera" className="arg__img" />
            </div>
            <div className="arg arg__solutions">
                <div className="arg__text">
                    <h2 className="arg__header">Решения</h2>
                    <p className="arg__p">Надежные инженерные системы, грамотно спроектированные с учетом последних технологий в области безопасности и IT. Мы уверены в качестве выполняемых работ, монтируемого оборудования и предоставляем гарантию на все установленные системы.</p>
                </div>
                <img src={imgSolutions} alt="wire" className="arg__img" />
            </div>
        </section>
    )
}

export default About;