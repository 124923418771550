import { Link } from 'react-router-dom';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import tgChannal from '../../../assets/images/project__AU_Arena_Chimki.webp';
import './footer.scss'

const Footer: React.FC = () => {

    const stylesForMap = {
        minHeight: '200px'
    }
    return (
        <footer className='footer' id='footer'>
            <div className="footer__text">
                <h2 className="footer__text-h2">Контакты</h2>
                <div className="phone">
                    <h3 className="footer__text-h3">Телефон</h3>
                    <p className="footer__text-p">+7(495) 925-30-75</p>
                </div>
                <div className="info">
                    <h3 className="footer__text-h3">Email</h3>
                    <p className="footer__text-p">info@rightsystems.pro</p>
                </div>
                <div className="support">
                    <h3 className="footer__text-h3">Тех. поддержка</h3>
                    <p className="footer__text-p">support@rightsystems.pro</p>
                </div>
                <div className="address">
                    <h3 className="footer__text-h3">Адрес</h3>
                    <p className="footer__text-p">105318, г. Москва, ул. Ткацкая, д. 1</p>
                </div>
                <div className="timeWork">
                    <h3 className="footer__text-h3">Время работы</h3>
                    <p className="footer__text-p">Понедельник—пятница: 9:00–18:00</p>
                    <p className="footer__text-p">Суббота и воскресенье: 11:00–16:00</p>
                </div>
                <Link to='/policy' className="policy__link">Политика конфиденциальности</Link>
                <div className="contact">
                    <h3 className="footer__text-h3">Связаться с нами</h3>
                    <a href="https://t.me/RSS_Team_Bot" target="_blank" className="contact__TG">
                      <svg role="presentation" width="30px" height="30px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm21.977-68.056c.386-4.38-4.24-2.576-4.24-2.576-3.415 1.414-6.937 2.85-10.497 4.302-11.04 4.503-22.444 9.155-32.159 13.734-5.268 1.932-2.184 3.864-2.184 3.864l8.351 2.577c3.855 1.16 5.91-.129 5.91-.129l17.988-12.238c6.424-4.38 4.882-.773 3.34.773l-13.49 12.882c-2.056 1.804-1.028 3.35-.129 4.123 2.55 2.249 8.82 6.364 11.557 8.16.712.467 1.185.778 1.292.858.642.515 4.111 2.834 6.424 2.319 2.313-.516 2.57-3.479 2.57-3.479l3.083-20.226c.462-3.511.993-6.886 1.417-9.582.4-2.546.705-4.485.767-5.362Z" fill="#ffffff"></path></svg>
                    </a>
                </div>
                <div className="tgChannal">
                    <h3 className="footer__text-h3">Наш Telegram-канал</h3>
                    <a href="https://t.me/CCTVsafety" target="_blank" className="contact__TG">
                        <img src={tgChannal} alt="camera" className='tgChannal__img'/>
                        <p id='tgChannal__name' className="footer__text-p">Видеобезопасность</p>
                    </a>
                </div>
            </div>
            <YMaps>
                    <Map width={"100%"} height={"100%"}
                        style={stylesForMap}
                        defaultState={{ 
                            center: [55.786424, 37.718857],
                            zoom: 15 }}>
                        <Placemark geometry={[55.786424, 37.718857]} />
                    </Map> 
            </YMaps>
        </footer>
    )
}

export default Footer;